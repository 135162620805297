import React, { useState, useEffect } from 'react';
import { Building, Mail, MapPin, Phone, ChevronLeft, ChevronRight } from 'lucide-react';
import { useForm, ValidationError } from '@formspree/react';
import Building381East166thSt from './assets/381 East 166th Street.jpg';
import Building471East184thSt from './assets/471 East 184th Street.jpg';
import Building2250BassfordAve from './assets/2250 Bassford Ave.jpg';
import Building2311WhitePlainsRd from './assets/2311 White Plains Rd.jpg';
import Building790Route312 from './assets/790 Brewster.jpg';
import Building2720ClaflinAve from './assets/2720 Claflin Ave.jpg';
import logo from './assets/logo.png';
import backgroundGif from './assets/back.gif';

function ContactForm() {
  const [state, handleSubmit] = useForm("xovavwql");
  if (state.succeeded) {
    return <p>Thanks for your message!</p>;
  }
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        id="name"
        type="text"
        name="name"
        placeholder="Name"
        className="w-full p-2 bg-gray-800 border border-gray-700 rounded"
        required
      />
      <ValidationError
        prefix="Name"
        field="name"
        errors={state.errors}
      />
      <input
        id="email"
        type="email"
        name="email"
        placeholder="Email"
        className="w-full p-2 bg-gray-800 border border-gray-700 rounded"
        required
      />
      <ValidationError
        prefix="Email"
        field="email"
        errors={state.errors}
      />
      <textarea
        id="message"
        name="message"
        placeholder="Message"
        rows="4"
        className="w-full p-2 bg-gray-800 border border-gray-700 rounded"
        required
      />
      <ValidationError
        prefix="Message"
        field="message"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting} className="px-4 py-2 rounded transition-colors duration-300 bg-white text-black hover:bg-gray-200">
        Send Message
      </button>
      {state.succeeded && <p className="text-green-500">Message sent successfully!</p>}
    </form>
  );
}

const CandorWebsite = () => {
  const [activeTab, setActiveTab] = useState('about');
  const [activeProperty, setActiveProperty] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
        } else {
          entry.target.classList.remove('fade-in');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('.animate-on-scroll').forEach(el => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  const buildings = [
    {
      address: '2720 Claflin Avenue, Bronx, NY 10468',
      units: 15,
      sqft: 14350,
      year: 2021,
      image: Building2720ClaflinAve
    },
    {
      address: '471 East 184th Street, Bronx, NY 10468',
      units: 8,
      sqft: 7800,
      year: 2016,
      image: Building471East184thSt
    },
    {
      address: '381 East 166th Street, Bronx, NY 10465',
      units: 12,
      sqft: 10600,
      year: 2019,
      image: Building381East166thSt
    },
    {
      address: '2250 Bassford Avenue, Bronx, NY 10467',
      units: 10,
      sqft: 9320,
      year: 2017,
      image: Building2250BassfordAve
    },
    {
      address: '2311 White Plains Road, Bronx, NY 10467',
      units: 51,
      sqft: 51020,
      year: 2024,
      image: Building2311WhitePlainsRd
    },
    {
      address: '790 Route 312, Brewster, NY 10509',
      units: 32,
      acres: 56,
      image: Building790Route312
    },
  ];

  const nextProperty = () => {
    setActiveProperty((prev) => (prev + 1) % buildings.length);
  };

  const prevProperty = () => {
    setActiveProperty((prev) => (prev - 1 + buildings.length) % buildings.length);
  };

  return (
    <div className="min-h-screen bg-black text-white font-sans">
      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .animate-on-scroll {
          opacity: 0;
          transition: opacity 0.5s, transform 0.5s;
        }
        .fade-in {
          animation: fadeIn 1s forwards;
        }
        .property-image {
          transition: all 0.5s ease-in-out;
        }
        .property-image.active {
          z-index: 10;
          transform: scale(1.1);
        }
        .property-image.prev {
          transform: scale(0.9) translateX(-10%) rotate(-5deg);
        }
        .property-image.next {
          transform: scale(0.9) translateX(10%) rotate(5deg);
        }
        .hero-section {
          background-image: url(${backgroundGif});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
        @media (max-width: 768px) {
          .header-title {
            display: none;
          }
          .header-logo {
            display: block;
            position: relative;
            height: 50px;
            width: auto;
          }
        }
        @media (max-width: 480px) {
          .header-logo {
            height: 40px;
            width: auto;
          }
        }
      `}</style>

      <header className="bg-gray-800 bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-md fixed w-full z-10">
        <div className="container mx-auto px-4 py-6 flex justify-between items-center">
          <img src={logo} alt="Candor Management Group Logo" className="header-logo h-24 w-26 fixed" />
          <nav className="flex-1 flex justify-end">
            <ul className="flex space-x-6">
              {['about', 'services', 'properties', 'contact'].map((tab) => (
                <li key={tab}>
                  <a
                    href={`#${tab}`}
                    className={`text-gray-300 hover:text-white capitalize transition-colors duration-300 ${activeTab === tab ? 'text-white font-semibold' : ''
                      }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </header>

      <section className="hero-section h-screen flex items-center">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-6xl font-bold mb-4 animate-on-scroll">Candor Management Group</h1>
          <p className="text-xl mb-8 animate-on-scroll">
            Elevating multifamily housing management with candid, first-class services across the New York metro area.
          </p>
          <div className="space-x-4">
            <a href="#contact" className="bg-white bg-opacity-75 text-black px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transition-colors duration-300 animate-on-scroll">
              Contact Us
            </a>
            <a href="#about" className="bg-gray-700 bg-opacity-75 text-white px-6 py-3 rounded-full font-semibold hover:bg-opacity-100 transition-opacity duration-300 animate-on-scroll">
              Learn More
            </a>
          </div>
        </div>
      </section>

      <main className="container mx-auto px-4 py-24">
        <section id="about" className="mb-24 scroll-mt-24">
          <h2 className="text-4xl font-bold mb-8 relative inline-block animate-on-scroll">
            Our Mission
            <span className="absolute bottom-0 left-0 w-full h-1 bg-white transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-gray-900 rounded-lg p-6 animate-on-scroll">
              <h3 className="text-2xl font-semibold mb-4">Candid Approach</h3>
              <p className="text-gray-300">
                CMG’s management approach of thinking as an owner provides clients with a feeling of <span className="font-bold text-blue-500">candor</span> that many competitors lack. Proactively sourcing creative strategies and maintaining open lines of communication while simultaneously executing promptly on deliverables is what differentiates us from our competitors.
              </p>
            </div>
            <div className="bg-gray-900 rounded-lg p-6 animate-on-scroll">
              <h3 className="text-2xl font-semibold mb-4">First Class Service</h3>
              <p className="text-gray-300">
                CMG’s dynamic capability of managing different types of multifamily housing as well as construction projects allows the firm to position itself as a “one stop shop” for many clients, creating value for those who seek a firm that is versatile.
              </p>
            </div>
            <div className="bg-gray-900 rounded-lg p-6 animate-on-scroll">
              <h3 className="text-2xl font-semibold mb-4">Local Expertise</h3>
              <p className="text-gray-300">
                CMG has extensive experience in dealing with federal and local agencies such as:
                <ul className="list-disc list-inside">
                  <li>HUD, HPD, NYCHA, DHCR, DOB, DEP, FDNY, and more.</li>
                </ul>
              </p>
            </div>
          </div>
        </section>

        <section id="services" className="mb-24 scroll-mt-24">
          <h2 className="text-4xl font-bold mb-8 relative inline-block animate-on-scroll">
            Our Services
            <span className="absolute bottom-0 left-0 w-full h-1 bg-white transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gray-900 rounded-lg p-6 transition-transform duration-300 hover:scale-105 animate-on-scroll">
              <h3 className="text-2xl font-semibold mb-4">Primary Services</h3>
              <ul className="list-none space-y-2">
                {['Property Management', 'Construction Management', 'Asset Management'].map((service, index) => (
                  <li key={index} className="flex items-center">
                    <span className="w-2 h-2 bg-white rounded-full mr-2"></span>
                    {service}
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-gray-900 rounded-lg p-6 transition-transform duration-300 hover:scale-105 animate-on-scroll">
              <h3 className="text-2xl font-semibold mb-4">Additional Services</h3>
              <ul className="list-none space-y-2">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-white rounded-full mr-2"></span>
                  Development and execution of value-add investment strategies
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-white rounded-full mr-2"></span>
                  Energy consulting and efficiency initiatives
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-white rounded-full mr-2"></span>
                  Government compliance (City & State)
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section id="properties" className="mb-24 scroll-mt-24">
          <h2 className="text-4xl font-bold mb-8 relative inline-block animate-on-scroll">
            Our Properties
            <span className="absolute bottom-0 left-0 w-full h-1 bg-white transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-center gap-8 animate-on-scroll">
            <div className="relative w-80 h-80">
              {buildings.map((building, index) => (
                <div
                  key={index}
                  className={`property-image absolute top-0 left-0 w-full h-full ${index === activeProperty
                    ? 'active'
                    : index === (activeProperty - 1 + buildings.length) % buildings.length
                      ? 'prev'
                      : index === (activeProperty + 1) % buildings.length
                        ? 'next'
                        : 'hidden'
                    }`}
                >
                  <img
                    src={building.image}
                    alt={building.address}
                    className="w-full h-full object-cover rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-col items-center md:items-start">
              <h3 className="text-2xl font-semibold mb-4">{buildings[activeProperty].address}</h3>
              <p className="flex items-center mb-2">
                <Building className="mr-2" size={18} />
                {buildings[activeProperty].units} Unit{buildings[activeProperty].units > 1 ? 's' : ''}
              </p>
              {buildings[activeProperty].sqft && (
                <p className="mb-2">{buildings[activeProperty].sqft.toLocaleString()} sq/ft</p>
              )}
              {buildings[activeProperty].acres && (
                <p className="mb-2">{buildings[activeProperty].acres} acres</p>
              )}
              {buildings[activeProperty].year && (
                <p>Built in {buildings[activeProperty].year}</p>
              )}
              <div className="flex mt-4 space-x-4">
                <button
                  onClick={prevProperty}
                  className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors duration-300"
                >
                  <ChevronLeft size={24} />
                </button>
                <button
                  onClick={nextProperty}
                  className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors duration-300"
                >
                  <ChevronRight size={24} />
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="scroll-mt-24">
          <h2 className="text-4xl font-bold mb-8 relative inline-block animate-on-scroll">
            Contact Us
            <span className="absolute bottom-0 left-0 w-full h-1 bg-white transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>
          </h2>
          <div className="bg-gray-900 rounded-lg p-8 animate-on-scroll">
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-2xl font-semibold mb-4">Get in Touch</h3>
                <p className="flex items-center mb-4">
                  <Mail className="mr-2" size={18} />
                  <a href="mailto:info@candormg.com" className="hover:underline">info@candormg.com</a>
                </p>
                <p className="flex items-center mb-4">
                  <Phone className="mr-2" size={18} />
                  <span>(347) 474-9196</span>
                </p>
                <p className="flex items-center">
                  <MapPin className="mr-2" size={18} />
                  <span>New York Metro Area</span>
                </p>
              </div>
              <ContactForm />
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Candor Management Group LLC. All rights reserved.</p>

        </div>
      </footer>

    </div>
  );
};

export default CandorWebsite;
